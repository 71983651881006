import "./App.css";
import { useEffect } from "react";
import Header from "./components/Header";
import Landingpage from "./First/Landingpage";
import Booking from "./Third/Booking";
import OrderSummary from "./OrderSummary";
import ShowLabs from "./components/ShowLabs";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import BottomNavigationElement from "./components/BottomNavigationElement";
import Radiology from "./HealthTests/Radiology/Radiology";
import PakagesTab from "./second/PakagesTab";
import ListPakage from "./DesktopPages/ListPakage";
import Login from "./components/Login";
import SignupPage from "./components/SignupPage";
import Fotter from "./components/Fotter";
import Dashboard from "./Dashboard/Dashboard";
import { useSelector } from "react-redux";
import ManagePackage from "./Dashboard/Manage/ManagePackage/ManagePackage";
import ManageCustomers from "./Dashboard/Manage/ManageCustomers/ManageCustomers";
import SlideShow from "./Dashboard/Appearance/SlideShow/SlideShow";
import AddPackage from "./Dashboard/Manage/ManagePackage/AddPackage";
import AddBanner from "./Dashboard/Appearance/SlideShow/AddBanner";
import PrescriptionUploaded from "./Dashboard/Healthexpert/PrescriptionUploaded/PrescriptionUploaded";
import ManageEmployees from "./Dashboard/Manage/ManageEmployees/ManageEmployees";
import ManageLocation from "./Dashboard/Manage/ManageLocation/ManageLocation";
import ManageTest from "./Dashboard/Manage/ManageTests/ManageTest";
import ManageRadTests from "./Dashboard/Manage/ManageRadiologyTests/ManageRadTest";
import ManageDiscounts from "./Dashboard/Manage/ManageDiscount/ManageDiscount";
import HighlightPackages from "./Dashboard/Appearance/HighlightPackages/HighlighPackages";
import HighlightTests from "./Dashboard/Appearance/HighlightTests/HighlightTests";
import Orders from "./Dashboard/Dashboard/Orders/Orders";
import CustomerComplaints from "./Dashboard/Healthexpert/CustomerComplaints/CustomerComplaints";
import ManagePartnerlabs from "./Dashboard/Manage/ManagePartnerLabs/ManagePartnerLabs";
import ManageRadPartners from "./Dashboard/Manage/ManageRadiologyPartners/ManageRadPartners";
import Analytics from "./Dashboard/Dashboard/Analytics/Analytics";
import AddLab from "./Dashboard/Manage/ManagePartnerLabs/AddLab";
import AddRadLab from "./Dashboard/Manage/ManageRadiologyPartners/AddRadLab";
import AddDiscount from "./Dashboard/Manage/ManageDiscount/AddDiscount";
import AddTest from "./Dashboard/Manage/ManageTests/AddTest";
import AddRadTest from "./Dashboard/Manage/ManageRadiologyTests/AddRadTest";
import AddLocation from "./Dashboard/Manage/ManageLocation/AddLocation";
import ScrollMenu from "./Dashboard/Appearance/ScrollMenu/Scrollmenu";
import AddScrollmenu from "./Dashboard/Appearance/ScrollMenu/AddScrollmenu";
import LabsAndPackages from "./Dashboard/Manage/ManagePartnerLabs/LabsAndPackages";
import EditPackage from "./Dashboard/Manage/ManagePartnerLabs/EditPackage";
import EditTest from "./Dashboard/Manage/ManagePartnerLabs/EditTest";
import MyAddress from "./components/MyInfo/MyAddress";
import MyOffers from "./components/MyInfo/MyOffers";
import MyBookings from "./components/MyInfo/MyBookings";
import MyReports from "./components/MyInfo/MyReports";
import UploadReports from "./Dashboard/Dashboard/Orders/UploadReports";
import ScrollTotop from "./ScrollTotop";
import ChangeStatus from "./Dashboard/Dashboard/Orders/ChangeStatus";
import RegisterUser from "./components/RegisterUser";
import AddComplaint from "./Dashboard/Healthexpert/CustomerComplaints/AddComplaint";
import LabsPack from "./Dashboard/Appearance/ScrollMenu/LabsPack";
import EditScrollMenu from "./Dashboard/Appearance/ScrollMenu/EditScrollMenu";
import AddHighlightTest from "./Dashboard/Appearance/HighlightTests/AddHighlightTest";
import AddHighlightPackage from "./Dashboard/Appearance/HighlightPackages/AddHighlightPackage";
import Search from "./components/Search/Search";
import Scroll from "./components/Scroll/Scroll";
import Footer from "./components/Footer";
import PrivacyPolicy from "./components/PrivacyPages/privacyPage";
import Terms from "./components/PrivacyPages/termsPage";
import Contact from "./components/PrivacyPages/contact";
import RefundPolicy from "./components/PrivacyPages/refundPolicy";
import AboutUs from "./components/PrivacyPages/aboutUs";
import MinimalLayout from "./layout/MinimalLayout";
import TestRoute from "./Routes";
import MainLayout from "./layout/MainLayout";
import ThemeProvider from "./theme";
import DashboardDefault from "./components/Dashboard/Analytics";
import HealthIssues from "./Dashboard/Appearance/Healthissue/Healthissue";
import HealthIssuesLabPacks from "./Dashboard/Appearance/Healthissue/LabPack";
import HealthIssue from "./components/Dashboard/cards/healthissues";

//  function redirectHTTP() {
//    if (window.location.protocol === 'http:') {
//      window.location.href = 'https://' + window.location.host + window.location.pathname;
//    }
//  }
// redirectHTTP();

function App() {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    document.addEventListener("contextmenu", (e) => {
      e.preventDefault();
    });
  });

  return (
    <ThemeProvider>
      <div className="App">
        {/* <TestRoute /> */}
        {userInfo ? (
          <BrowserRouter>
            <Routes />
            {!userInfo.isAdmin && <Header />}
            <ScrollTotop>
              {!userInfo.isAdmin ? (
                <Routes>
                  <Route path="/" element={<Landingpage />} />

                  <Route path="/BookingPackages" element={<ListPakage />} />
                  <Route path="/selectLab" element={<ShowLabs />} />

                  <Route path="/scroll/:id" element={<Scroll />} />
                  <Route path="/healthissue/:id" element={<HealthIssue />} />
                  <Route path="/search/" element={<Search />} />
                  <Route path="/Booking" element={<Booking />} />
                  <Route path="/MyBooking" element={<MyBookings />} />
                  <Route path="/MyReport" element={<MyReports />} />
                  <Route path="/MyAddress" element={<MyAddress />} />
                  <Route path="/MyOffers" element={<MyOffers />} />
                  <Route path="/privacypolicy" element={<PrivacyPolicy />} />
                  <Route path="/refundpolicy" element={<RefundPolicy />} />
                  <Route path="/about" element={<AboutUs />} />
                  <Route path="/terms" element={<Terms />} />
                  <Route path="/contact" element={<Contact />} />
                  <Route path="/onSummary" element={<OrderSummary />} />

                  <Route path="/TestPakage" element={<PakagesTab />} />
                  <Route path="/Radiology" element={<Radiology />} />
                  <Route path="*" element={<Navigate to="/" />} />
                </Routes>
              ) : (
                <Routes>
                  <Route path="/dashboard" element={<MainLayout />}>
                    <Route path="" element={<Navigate to="analytics" />} />
                    <Route path="analytics" element={<DashboardDefault />} />
                    <Route
                      path="highlightPackages"
                      element={<HighlightPackages />}
                    />
                    <Route path="highlightTests" element={<HighlightTests />} />
                    <Route path="scrollMenu" element={<ScrollMenu />} />
                    <Route path="healthissues" element={<HealthIssues />} />
                    <Route path="slideShow" element={<SlideShow />} />

                    <Route path="orders" element={<Orders />} />

                    <Route
                      path="customerComplaints"
                      element={<CustomerComplaints />}
                    />
                    <Route
                      path="prescriptionUploaded"
                      element={<PrescriptionUploaded />}
                    />

                    <Route path="managePackage" element={<ManagePackage />} />
                    <Route
                      path="manageCustomers"
                      element={<ManageCustomers />}
                    />
                    <Route
                      path="manageEmployees"
                      element={<ManageEmployees />}
                    />
                    <Route path="manageLocation" element={<ManageLocation />} />
                    <Route path="manageTest" element={<ManageTest />} />
                    <Route
                      path="manageDiscount"
                      element={<ManageDiscounts />}
                    />
                    <Route path="manageRadTests" element={<ManageRadTests />} />
                    <Route
                      path="managePartnerLabs"
                      element={<ManagePartnerlabs />}
                    />
                    <Route
                      path="manageRadPartners"
                      element={<ManageRadPartners />}
                    />
                    <Route path="LabsPack" element={<LabsPack />} />
                    <Route
                      path="healthLabsPack"
                      element={<HealthIssuesLabPacks />}
                    />
                    <Route path="editScrollMenu" element={<EditScrollMenu />} />
                    <Route
                      path="addHighlightTest"
                      element={<AddHighlightTest />}
                    />
                    <Route
                      path="addHighlightPackage"
                      element={<AddHighlightPackage />}
                    />

                    <Route
                      path="LabsAndPackages"
                      element={<LabsAndPackages />}
                    />
                    <Route path="editPackage" element={<EditPackage />} />
                    <Route path="editTest" element={<EditTest />} />
                    <Route path="addTest" element={<AddTest />} />
                    <Route path="addRadTest" element={<AddRadTest />} />
                    <Route path="addLab" element={<AddLab />} />
                    <Route path="addRadLab" element={<AddRadLab />} />
                    <Route path="addDiscount" element={<AddDiscount />} />
                    <Route path="addLocation" element={<AddLocation />} />
                    <Route path="addPackage" element={<AddPackage />} />
                    <Route path="addBanner" element={<AddBanner />} />
                    <Route path="addScrollmenu" element={<AddScrollmenu />} />
                    <Route path="uploadReports" element={<UploadReports />} />
                    <Route path="changeStatus" element={<ChangeStatus />} />
                    <Route path="addComplaint" element={<AddComplaint />} />
                  </Route>

                  <Route
                    path="*"
                    element={<Navigate to="/dashboard/analytics" />}
                  />
                </Routes>
              )}
              {!userInfo?.isAdmin && <Footer />}
              {!userInfo?.isAdmin && <BottomNavigationElement />}
            </ScrollTotop>
          </BrowserRouter>
        ) : (
          <BrowserRouter>
            <ScrollTotop>
              <Routes>
                <Route
                  path="/"
                  element={
                    <>
                      <Header />
                      <Landingpage />
                      <Footer />
                      <BottomNavigationElement />
                    </>
                  }
                />
                <Route
                  path="/scroll/:id"
                  element={
                    <>
                      <Header />
                      <Scroll />
                      <Footer />
                      <BottomNavigationElement />
                    </>
                  }
                />
                <Route
                  path="/healthissue/:id"
                  element={
                    <>
                      <Header />
                      <HealthIssue />
                      <Footer />
                      <BottomNavigationElement />
                    </>
                  }
                />
                <Route
                  path="/search/"
                  element={
                    <>
                      <Header />
                      <Search />
                      <Footer />
                      <BottomNavigationElement />
                    </>
                  }
                />
                <Route
                  path="/privacypolicy"
                  element={
                    <>
                      <Header />
                      <PrivacyPolicy />
                      <Footer />
                      <BottomNavigationElement />
                    </>
                  }
                />
                <Route
                  path="/terms"
                  element={
                    <>
                      <Header />
                      <Terms />
                      <Footer />
                      <BottomNavigationElement />
                    </>
                  }
                />
                <Route
                  path="/about"
                  element={
                    <>
                      <Header />
                      <AboutUs />
                      <Footer />
                      <BottomNavigationElement />
                    </>
                  }
                />
                <Route
                  path="/refundpolicy"
                  element={
                    <>
                      <Header />
                      <RefundPolicy />
                      <Footer />
                      <BottomNavigationElement />
                    </>
                  }
                />
                <Route
                  path="/contact"
                  element={
                    <>
                      <Header />
                      <Contact />
                      <Footer />
                      <BottomNavigationElement />
                    </>
                  }
                />

                <Route
                  path="/BookingPackages"
                  element={
                    <>
                      <Header />
                      <ListPakage />
                      <Footer />
                      <BottomNavigationElement />
                    </>
                  }
                />
                <Route
                  path="/selectLab"
                  element={
                    <>
                      <Header />
                      <ShowLabs />
                      <Footer />
                      <BottomNavigationElement />
                    </>
                  }
                />

                <Route path="/Register" element={<SignupPage />} />
                <Route path="/RegisterUser" element={<RegisterUser />} />
                <Route
                  path="*"
                  element={<Navigate to="/" state={{ popup: true }} />}
                />
              </Routes>
            </ScrollTotop>
          </BrowserRouter>
        )}
      </div>
    </ThemeProvider>
  );
}

export default App;
