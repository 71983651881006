import { Box, Stack, Typography } from "@mui/material";

export default function AboutUs() {
  return (
    <Box
      //   padding={4}
      width={{ xs: "75%", sm: "80%", md: "60%" }}
      //   width="978px"
      margin="1rem auto"
      padding="32px"
      backgroundColor="#f1f3f6"
      borderRadius="8px"
    >
      <Typography
        fontSize="1.5rem"
        fontWeight="bold"
        textAlign="center"
        color="#002984"
        marginBottom="1rem"
        url="https://www.apple.com/legal/images/icon_dataprivacy_2x.png"
      >
        ABOUT US
      </Typography>
      <Stack spacing={3}>
        <Typography>
          Welcome to Labtin, your premier source for high-quality
          diagnostic services. Our team of experienced medical professionals is
          dedicated to providing you with accurate and timely results, as well
          as exceptional customer service.
        </Typography>
        <Stack spacing={0}>
          <Typography>
            At Labtin, we understand that receiving a diagnosis can be a
            stressful experience, which is why we strive to make the process as
            smooth and easy as possible. Our state-of-the-art facilities are
            equipped with the latest technology and equipment, allowing us to
            provide you with accurate and reliable results.
          </Typography>

          <Typography>
            Our team of medical professionals is composed of highly trained and
            experienced individuals who are passionate about their work. They
            are committed to providing you with personalized care and attention,
            ensuring that you receive the best possible service.
          </Typography>

          <Typography>
            We believe that everyone should have access to high-quality
            diagnostic services, which is why we offer affordable pricing and
            work with most major insurance providers. We are also committed to
            protecting your privacy and confidentiality, and we adhere to strict
            standards of data protection and security.
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
}
