import React, { useEffect, useState } from "react";
import Snackbar, { SnackbarOrigin } from "@mui/material/Snackbar";
import { useLocation, useNavigate } from "react-router-dom";
import "./OrderSummary.css";
import discountIcon from "./image/20.png";
import { getDiscounts } from "./actions/packageActions";
import { useDispatch, useSelector } from "react-redux";
import { Check, Close, Search } from "@mui/icons-material";
import { createOrder } from "./actions/orderActions";
// import crypto from "crypto";
import sha512 from "crypto-js/sha512";
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  styled,
  Button,
  Modal,
  Backdrop,
  Slide,
  Stack,
  useMediaQuery,
  IconButton,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  InputAdornment,
  OutlinedInput,
  Fade,
  Tooltip,
} from "@mui/material";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import axios from "axios";
// import payu from "payu-sdk";

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#1976d2",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#1976d2",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: "#1976d2",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const style1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const style = {
  position: "absolute",
  overflowY: "auto",
  borderRadius: "15px",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "0.4rem",
  boxShadow: "0 0.5rem 1rem rgba(69, 69, 69, 0.5)",
};

const OrderSummary = () => {
  const loc = useLocation();
  const matches = useMediaQuery("(max-width:600px)");
  const [successModal, setSuccessModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.userLogin);
  const data = loc.state;
  console.log(data);
  const [state, setState] = React.useState({
    open: false,
    vertical: "bottom",
    horizontal: "center",
  });
  const [value, setValue] = React.useState("cashOnDelivery");

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const navigate = useNavigate();
  const { vertical, horizontal, open } = state;

  const handleClose = () => {
    setState({ ...state, open: false });
  };
  const handleClick = () => {
    setState({ ...state, open: true });
  };

  const { discountList } = useSelector((state) => state.discounts);

  const hiddenDiscounts = discountList.filter((d) => d.hidden == "Yes");
  const availableDiscounts = discountList.filter((d) => d.hidden == "No");
  const applicableDiscounts = availableDiscounts.filter((d) =>
    d.applicableTo == "new" ? data.newUser : true
  );
  useEffect(() => {
    dispatch(getDiscounts());
  }, [dispatch]);

  const [openDiscounts, setOpenDiscounts] = useState(false);
  const [discount, setDiscount] = useState({ code: "", percent: 0 });
  const [uniDiscount, setUniDiscount] = useState("");
  const [appDiscount, setAppDiscount] = useState(0);

  const addUniDiscount = () => {
    const dis = hiddenDiscounts.find((f) => f.promoCode === uniDiscount);
    setDiscount({
      code: dis.promoCode,
      percent: dis.discountPercentage,
    });
    setAppDiscount(
      (Number(data.price.discountPrice | data.price) *
        Number(dis.discountPercentage)) /
        100
    );
  };
  console.log(hiddenDiscounts);
  const removeHandler = () => {
    setDiscount({ code: "", percent: 0 });
    setUniDiscount("");
    setAppDiscount(0);
  };

  const handlePayment = async () => {
    console.log("payment");
    const PAYU_MERCHANT_KEY = "LLhkyJ";
    const PAYU_MERCHANT_SALT = "GmJWMxOu";
    const PAYU_TEST_URL = "https://test.payu.in/_payment";
    const PAYU_PRODUCTION_URL = "https://secure.payu.in/_payment";

    // Generate a unique transaction ID
    const txnid = `TXN-${Date.now()}`;

    // Calculate the hash
    const hashString =
      PAYU_MERCHANT_KEY +
      "|" +
      txnid +
      "|" +
      100 +
      "|" +
      "product" +
      "|" +
      "First Name" +
      "|" +
      "first@gmail.com" +
      "||||||||||" +
      PAYU_MERCHANT_SALT;

    const hash = sha512(hashString).toString();

    // Prepare the request payload
    const requestPayload = {
      key: PAYU_MERCHANT_KEY,
      txnid: txnid,
      amount: 100,
      productinfo: "product",
      firstname: "First Name",
      email: "first@gmail.com",
      phone: 8979879879,
      surl: "https://labtin.com",
      furl: "https://labtin.com",
      hash: hash,
      environment: "test",
    };

    try {
      const res = await axios.post(PAYU_TEST_URL, requestPayload);
      console.log(res);
    } catch (err) {
      console.log(err);
    }
    // fetch(PAYU_TEST_URL, requestPayload)
    //   .then((response) => {
    //     res.send(response.data);
    //   })
    //   .catch((error) => {
    //     res.status(500).send(error.message);
    //   });

    // console.log(res);
  };

  // const payuClient = new payu.PayUClient({
  //   client_id:
  //     "b521247412ae2d72de4c1a71e0cab4abc6d7cce2d3509dee267d3602370eb316",
  //   client_secret:
  //     "ea08ea75922ff4cb00dd4556b3b1536438cd87a5c3c163c45b4b79111baa9de7",
  // });
  // const paymentRequest = {
  //   customer: {
  //     name: "John Doe",
  //     email: "johndoe@example.com",
  //     phone: "1234567890",
  //   },
  //   amount: {
  //     value: "100.00",
  //     currency: "INR",
  //   },
  // };
  // payuClient.payments
  //   .create(paymentRequest)
  //   .then((response) => {
  //     const redirectUrl = response.redirectUri;
  //     payu.redirect.paymentservices.start(redirectUrl);
  //   })
  //   .catch((error) => {
  //     console.error(error);
  //   });

  const message = `Hello ${userInfo.name},%0aThank you for choosing Labtin Diagnostics,%0aYour Order Has Been Confirmed,%0aWe Will reach You by %0a${data.currentDate[0]} ${data.currentDate[2]} ${data.currentDate[1]}, ${data.time}.%0a%0aIf Any Changes, Feel Free To call us at 8686073073`;

  const admin = `Hello Admin,%0a

A new order has been placed by%0a ${userInfo.name},%0a${userInfo.number}%0a%0aHere are the details:%0a
Tests Booked:%0a ${data.test_titles.join("%0a ")}%0a
Amount In Rupees: ₹${(data.price.discountPrice | data.price) - appDiscount}%0a%0a
Please review and process this order as soon as possible.
%0a
Thank you`;

  const adminNumber = 9515450082;

  const action = (
    <Button
      onClick={async () => {
        dispatch(
          createOrder({
            ...data,
            totalPrice: (data.price.discountPrice | data.price) - appDiscount,
            discountUsed: discount,
          })
        );

        setState({ ...state, open: false });
        try {
          await axios.get(
            `https://whatsapp.labauto.in/api/send?number=91${userInfo.number}&type=text&message=${message}&instance_id=6697B2AE0F9A4&access_token=66979e95d92b0`
          );
        } catch (error) {
          console.log(error);
        }
        try {
          await axios.get(
            `https://whatsapp.labauto.in/api/send?number=91${adminNumber}&type=text&message=${admin}&instance_id=6697B2AE0F9A4&access_token=66979e95d92b0`
          );
        } catch (error) {
          console.log(error);
        }

        navigate("/", { state: { successModal: true } });
      }}
      variant="contained"
    >
      {value === "onlinePayment" ? "Proceed To Pay" : "Place Order"}
    </Button>
  );

  const steps = ["Find Test", "Select Lab", "Book Slot", "Checkout"];

  return (
    <>
      <Box sx={{ width: "100%", pt: "2rem", pb: "1rem", bgcolor: "#f5f5f5" }}>
        <Stepper activeStep={3} alternativeLabel connector={<QontoConnector />}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>

      {/* <Button onClick={() => handlePayment()}>submit</Button> */}

      <div className="Summary_main">
        <div className="OrderSummary_main">
          <div className="orderSummary_box1">
            <h3 className="headingOrder">Order Summary</h3>
            <h3 className="patientName">
              {data.selectedUser.name}{" "}
              <span style={{ fontSize: "80%" }}>
                {data.selectedUser.age}/{data.selectedUser.sex}
              </span>
            </h3>
            <div className="pakageName">
              <h3>{data.test_titles.join(",")}</h3>
              {/* <h3>10-12hr fasting is Required</h3> */}
            </div>
            <div className="pakageAvialability">
              <p>Pickup Scheduled on</p>
              <h3>
                {data.currentDate[0]} , {data.currentDate[2]}nd{" "}
                {data.currentDate[1]} , {data.time}
              </h3>
              <p>Sample Collection Address</p>
              <h3>
                {data.address.place},{data.address.city},{data.address.state},
              </h3>
            </div>
          </div>
          <div className="AvailableCoupon desktopElement">
            <h2>Available Coupons</h2>
            {applicableDiscounts.length > 2 ? (
              <>
                <div className="Coupons_now">
                  <h3>
                    Use {applicableDiscounts[0].promoCode} promocode and get{" "}
                    {applicableDiscounts[0].discountPercentage}
                    {"% "}
                    discount
                  </h3>
                  <button
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setDiscount({
                        code: applicableDiscounts[0].promoCode,
                        percent: applicableDiscounts[0].discountPercentage,
                      });
                      setAppDiscount(
                        (Number(data.price.discountPrice | data.price) *
                          Number(applicableDiscounts[0].discountPercentage)) /
                          100
                      );
                    }}
                  >
                    Apply
                  </button>
                </div>
                <div className="Coupons_now">
                  <h3>
                    Use {applicableDiscounts[1].promoCode} promocode and get{" "}
                    {applicableDiscounts[1].discountPercentage}
                    {"% "}
                    discount
                  </h3>
                  <button
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setDiscount({
                        code: applicableDiscounts[1].promoCode,
                        percent: applicableDiscounts[1].discountPercentage,
                      });
                      setAppDiscount(
                        (Number(data.price.discountPrice | data.price) *
                          Number(applicableDiscounts[1].discountPercentage)) /
                          100
                      );
                    }}
                  >
                    Apply
                  </button>
                </div>
                <div className="Coupons_now">
                  <button onClick={() => setOpenDiscounts(true)}>
                    View More
                  </button>
                </div>
              </>
            ) : (
              applicableDiscounts.map((d) => (
                <div className="Coupons_now">
                  <h3>
                    Use {d.promoCode} promocode and get {d.discountPercentage}
                    {"% "}
                    discount
                  </h3>
                  <button
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setDiscount({
                        code: d.promoCode,
                        percent: d.discountPercentage,
                      });
                      setAppDiscount(
                        (Number(data.price.discountPrice | data.price) *
                          Number(d.discountPercentage)) /
                          100
                      );
                    }}
                  >
                    Apply
                  </button>
                </div>
              ))
            )}
          </div>
        </div>
        <div className="OrderSummary">
          <div className="offers_div_main desktopElement">
            <div onClick={() => setOpenModal(true)} className="offers_div">
              {/* <img src={discountIcon} alt="" /> */}
              <h2>Apply Promocodes</h2>{" "}
            </div>
            <div className="inputCoupon desktopElement">
              <input
                type="text"
                value={uniDiscount}
                onChange={(e) => setUniDiscount(e.target.value)}
              />
              <button onClick={addUniDiscount}>Apply</button>
            </div>
          </div>
          <div
            onClick={() => setOpenModal(true)}
            className="offers_div mobileElement"
          >
            <img src={discountIcon} alt="" />
            <h2>Unlock Offers Or Apply Promocodes</h2>{" "}
          </div>

          <div className="billDetails">
            <h3>Bill Details</h3>
            <Stack>
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={value}
                  onChange={handleChange}
                >
                  <Box
                    sx={{
                      backgroundColor: "#f5f5f5",
                      p: 1,
                      borderRadius: "5px",
                      my: 1,
                    }}
                    display="flex"
                    alignItems="center"
                    onClick={() => setValue("cashOnDelivery")}
                  >
                    <FormControlLabel
                      value="cashOnDelivery"
                      control={
                        <Radio
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: 30,
                            },
                          }}
                        />
                      }
                    />
                    <Stack>
                      <Typography sx={{ fontSize: "1rem", fontWeight: 700 }}>
                        Cash on Service
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "0.8rem",
                          fontWeight: 500,
                          color: "#757575",
                        }}
                      >
                        You can pay cash at the time of sample collection.
                      </Typography>
                    </Stack>
                  </Box>
                  <Tooltip title="Online Payment is not available">
                    <Box
                      sx={{
                        backgroundColor: "#f5f5f5",
                        p: 1,
                        borderRadius: "5px",
                        my: 1,
                        opacity: 0.7,
                      }}
                      display="flex"
                      alignItems="center"
                      // onClick={() => setValue("onlinePayment")}
                    >
                      <FormControlLabel
                        disabled
                        value="onlinePayment"
                        control={
                          <Radio
                            sx={{
                              "& .MuiSvgIcon-root": {
                                fontSize: 30,
                              },
                            }}
                          />
                        }
                      />
                      <Stack>
                        <Typography sx={{ fontSize: "1rem", fontWeight: 700 }}>
                          Online Payment -Currently Unavailable
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "0.8rem",
                            fontWeight: 500,
                            color: "#757575",
                          }}
                        >
                          You can pay online using your debit card, credit card
                          or net banking.
                        </Typography>
                      </Stack>
                    </Box>
                  </Tooltip>
                </RadioGroup>
              </FormControl>
            </Stack>
            <div className="billCalc">
              <div className="billrow">
                <p>Test Total</p>{" "}
                <h5>₹{data.price.discountPrice | data.price}</h5>
              </div>
              <div className="billrow">
                <p>Sample Collection Charges</p>{" "}
                <div className="billrow">
                  <h5 style={{ textDecoration: "line-through", color: "red" }}>
                    ₹150{" "}
                  </h5>{" "}
                  <span
                    style={{
                      textDecoration: "none",
                      color: "#0EB6FF",
                      fontWeight: "bold",
                    }}
                  >
                    FREE
                  </span>
                </div>
              </div>
              <div className="billrow">
                <p>Applied Discount</p>
                {discount.code && (
                  <p
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      cursor: "pointer",
                    }}
                    onClick={removeHandler}
                  >
                    Remove Discount
                  </p>
                )}{" "}
                <h5>₹{appDiscount}</h5>
              </div>
            </div>
          </div>
          <Snackbar
            sx={{ marginBottom: "5rem" }}
            anchorOrigin={{ vertical, horizontal }}
            open={!openModal}
            onClose={handleClose}
            message={`Total ₹${
              (data.price.discountPrice | data.price) - appDiscount
            }`}
            action={action}
            key={vertical + horizontal}
          />
        </div>
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={successModal}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={successModal}>
          <Box sx={style1}>
            <Stack spacing={2} alignItems="center">
              <IconButton sx={{ border: "2px solid #1565c0" }}>
                <Check sx={{ color: "#1565c0" }} />
              </IconButton>
              <Typography sx={{ fontSize: "1rem", fontWeight: 600 }}>
                Hey {data.selectedUser.name}!
              </Typography>
              <Stack spacing={0.7} alignItems="center">
                <Typography sx={{ fontSize: "1.2rem", fontWeight: 700 }}>
                  Your Order is Placed!
                </Typography>
                <Typography mb={2} sx={{ fontSize: "0.9rem", fontWeight: 500 }}>
                  Thank you for booking your test with us! Your test has been
                  booked successfully
                </Typography>
              </Stack>
              <Button
                variant="contained"
                sx={{
                  fontWeight: 700,
                }}
                onClick={() => navigate("/MyBooking")}
              >
                Track Order
              </Button>
            </Stack>
          </Box>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openModal || openDiscounts}
        onClose={() => {
          setOpenModal(false);
          setOpenDiscounts(false);
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        sx={{ display: "flex", justifyContent: " flex-end" }}
      >
        <Slide
          direction={matches ? "up" : "left"}
          // direction="up"
          timeout={500}
          in={openModal || openDiscounts}
          mountOnEnter
          unmountOnExit
        >
          <Box
            p={{ xs: 0, sm: 2 }}
            width={{ xs: "100%", sm: 430 }}
            top={{ xs: "30%", sm: "0" }}
            height={{ xs: "70%", sm: "100%" }}
            sx={style}
          >
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              mr={1}
              mt={3}
            >
              <Typography
                fontSize="1rem"
                fontWeight="700"
                mb="5px"
                color="rgb(74, 0, 153)"
              >
                Apply Promocodes
              </Typography>
              <Box
                width="100%"
                ml="1rem"
                display="flex"
                alignItems="center"
                pt="0.5rem"
                justifyContent="space-evenly"
              >
                <input
                  style={{
                    fontSize: "1.2rem",
                    padding: "0.5rem 0.8rem",
                    border: "none",
                    backgroundColor: "#ededed",
                    borderRadius: "0.2rem",
                  }}
                  type="text"
                  value={uniDiscount}
                  onChange={(e) => setUniDiscount(e.target.value)}
                />
                <Button
                  variant="contained"
                  onClick={addUniDiscount}
                  size="small"
                  sx={{ mx: 1 }}
                >
                  Apply
                </Button>
              </Box>
            </Box>
            <Stack
              sx={{
                overflowY: "scroll",
                overflowX: "hidden",
                mb: "2rem",
                scrollBehavior: "smooth",
                "&::-webkit-scrollbar": {
                  width: 7,
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#bdbdbd",
                  borderRadius: 2,
                },
              }}
            >
              <IconButton
                onClick={() => {
                  setOpenModal(false);
                  setOpenDiscounts(false);
                }}
                sx={{ position: "absolute", top: 0, right: 0 }}
              >
                <Close />
              </IconButton>
              <FormControl
                size="small"
                sx={{ m: 1, width: "90%", mt: "1rem" }}
                variant="outlined"
              >
                <OutlinedInput
                  id="outlined-adornment-password"
                  placeholder="Search for coupons"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton edge="end">
                        <Search />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              <div className="AvailableCoupon">
                <h2>Available Coupons</h2>

                {applicableDiscounts
                  .filter((a) =>
                    a.promoCode.toLowerCase().includes(search.toLowerCase())
                  )
                  .map((d) => (
                    <div className="Coupons_now">
                      <h3>
                        Use {d.promoCode} promocode and get{" "}
                        {d.discountPercentage}
                        {"% "}
                        discount
                      </h3>
                      <Button
                        variant="contained"
                        onClick={() => {
                          setDiscount({
                            code: d.promoCode,
                            percent: d.discountPercentage,
                          });
                          setAppDiscount(
                            (Number(data.price.discountPrice | data.price) *
                              Number(d.discountPercentage)) /
                              100
                          );
                          setOpenModal(false);
                        }}
                      >
                        Apply
                      </Button>
                    </div>
                  ))}
              </div>
            </Stack>
          </Box>
        </Slide>
      </Modal>
    </>
  );
};

export default OrderSummary;
