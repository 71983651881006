import React, { useEffect, useState } from "react";
import "./PopularPakages.css";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import { useSelector } from "react-redux";
import {
  Backdrop,
  Box,
  Button,
  IconButton,
  Slide,
  useMediaQuery,
} from "@mui/material";
import { NavigateNext } from "@mui/icons-material";

const PopularPakages = (props) => {
  const navigate = useNavigate();
  const { filter } = useSelector((state) => state.filter);
  const matches = useMediaQuery("(max-width:600px)");

  const [message, setMessage] = useState("Book Now");
  const addToCart = (e) => {
    if (props.cart.has(props.data._id)) {
      props.cart.delete(props.data._id);
      props.cart.size > 0
        ? props.setState({ ...props.state, open: true })
        : props.setState({ ...props.state, open: false });
      setMessage("Book Now");
    } else {
      var ct = props.cart.set(props.data._id, props.data);
      props.setCart(ct);
      props.cart.size > 0
        ? props.setState({ ...props.state, open: true })
        : props.setState({ ...props.state, open: false });
      setMessage("Remove Item");
    }
  };
  const filterAvailableIn = props.data.availableIn.filter(
    (f) => f.stateName?.toLowerCase() == filter?.toLowerCase()
  );
  const discount = Math.min(...filterAvailableIn.map((m) => m.discountPrice));
  const original = Math.min(...filterAvailableIn.map((m) => m.originalPrice));
  const discount_per = Math.trunc(((original - discount) / original) * 100);
  const details = props.data.details;
  const miniDetails =
    details.length < 50 ? details : details.slice(0, 60).concat(".......");

  useEffect(() => {
    if (props.cart.has(props.data._id)) {
      setMessage("Remove Item");
    } else {
      setMessage("Book Now");
    }
  }, [props.message]);

  return (
    <div>
      <div className="pakage_box">
        <div className="card_offer ">{discount_per}% off</div>
        <div className="pakage_details">
          <div
            style={{
              height: "5rem",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <h3 className="pakagesName">{props.data.title}</h3>
            {/* <p style={{ color: "red", fontSize: "80%", marginTop: "0.5rem " }}> */}
            <p className="requirements-text">
              {props.data.requirements || "REQUIRED FASTING"}
            </p>
          </div>
          {props.data.parameters && (
            <p className="IPara">
              Includes : {props.data.parameters} Parameters
            </p>
          )}
          <div
            style={{ paddingTop: "14px", fontSize: "0.95rem" }}
            className="pakage_about"
          >
            <ol className="pack_ol">
              <li>{miniDetails}</li>
            </ol>
            <button onClick={() => props.handleOpen(props.data)}>
              (Know more)
            </button>
          </div>
        </div>

        <div className="pakage_bottom">
          <div className="test_available">
            <div>Available In</div>
            <div style={{ fontWeight: "600" }}>
              {
                props.data.availableIn.filter(
                  (f) => f.stateName?.toLowerCase() == filter?.toLowerCase()
                ).length
              }{" "}
              Diagnostics
            </div>
          </div>

          <div className="pakage_price">
            <div className="test_price" style={{ fontWeight: "700" }}>
              Starts from
            </div>

            <div>
              <span style={{ textDecoration: "line-through", color: "red" }}>
                {original}
              </span>{" "}
              <span style={{ color: "purple", fontWeight: "bold" }}>
                ₹{discount}/-
              </span>
            </div>
          </div>
        </div>
        {/* <button className="BookNowButton" onClick={addToCart}>
          {message}
        </button> */}
        <Button
          sx={{ my: "0.5rem" }}
          fullWidth
          size={matches ? "small" : "medium"}
          variant="contained"
          onClick={addToCart}
        >
          {message}
        </Button>
      </div>
    </div>
  );
};

export default PopularPakages;
