import {
  Box,
  Button,
  Divider,
  FormControl,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { createBanner } from "../../../actions/appearanceActions";
import { API } from "../../../api";
import CustomLink from "../../../components/CustomLink";
import FormHeading from "../../../components/FormHeading";
import "./AddBanner.css";

const AddBanner = ({ handleClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [imgName, setImgName] = useState("");
  const handleAddBanner = async (e) => {
    e.preventDefault();

    dispatch(createBanner(data, navigate));
    handleClose();
  };
  const [data, setData] = useState({
    title: "",
    link: "",
    img: "",
    description: "",
  });

  const matches = useMediaQuery("(min-width:600px)");
  console.log(data);

  const uploadFileHandler = async (e) => {
    setImgName(e.target.files[0].name);
    const file = e.target.files[0];

    const formData = new FormData();
    formData.append("file", file);
    console.log(formData.get("file"));
    const res = await API.post("/upload", formData)
      .then((res) => {
        setData({
          ...data,
          img: res.data[0],
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <Stack p={4} spacing={matches ? 3 : 1}>
      <Typography
        variant="h4"
        sx={{ textAlign: "center", fontSize: "1.4rem", fontWeight: "700" }}
      >
        Add Banner
      </Typography>
      <Divider />
      <Stack>
        <FormControl>
          <Stack spacing={4}>
            <TextField
              size={matches ? "medium" : "small"}
              label="Title"
              variant="outlined"
              placeholder="Enter the Title"
              onChange={(e) => setData({ ...data, title: e.target.value })}
            />
            <TextField
              size={matches ? "medium" : "small"}
              label="Link"
              variant="outlined"
              placeholder="Enter the link"
              onChange={(e) => setData({ ...data, link: e.target.value })}
            />
            <TextField
              size={matches ? "medium" : "small"}
              label="Description"
              variant="outlined"
              placeholder="Enter the Description"
              onChange={(e) =>
                setData({ ...data, description: e.target.value })
              }
            />
            <Box display="flex" alignItems="center">
              <Button variant="contained" component="label" sx={{ mr: 5 }}>
                Upload
                <input
                  onChange={uploadFileHandler}
                  style={{ display: "none" }}
                  type="file"
                  name="bannerImage"
                  id="bannerImage"
                />
              </Button>
              {data.img && (
                <img
                  src={`https://server.labtin.com/${data.img}`}
                  height={50}
                  alt="not found"
                />
              )}
            </Box>

            <Button
              disabled={
                data.title === "" ||
                data.link === "" ||
                data.description === "" ||
                data.img === ""
              }
              variant="contained"
              onClick={handleAddBanner}
            >
              Submit
            </Button>
          </Stack>
        </FormControl>
      </Stack>
    </Stack>
    // <div className="add_banner">
    //   <CustomLink title={"Back to Slide Show"} />
    //   <FormHeading>Add Banner</FormHeading>
    //   <form onSubmit={handleAddBanner}>
    //     <input
    //       type="text"
    //       name="title"
    //       placeholder="Title"
    //       value={data.title}
    //       onChange={(e) => setData({ ...data, title: e.target.value })}
    //     />
    //     <input
    //       type="text"
    //       name="link"
    //       placeholder="Link"
    //       value={data.link}
    //       onChange={(e) => setData({ ...data, link: e.target.value })}
    //     />
    //     <textarea
    //       name="description"
    //       placeholder="Description"
    //       value={data.description}
    //       onChange={(e) => setData({ ...data, description: e.target.value })}
    //     />
    //     <label htmlFor="bannerImage" className="banner_input">
    //       {imgName ? "" : "Browse Image..."}
    //       <input
    //         onChange={uploadFileHandler}
    //         style={{ display: "none" }}
    //         type="file"
    //         name="bannerImage"
    //         id="bannerImage"
    //       />
    //       {imgName && <span className="img_name">{imgName}</span>}
    //     </label>
    //     <button type="submit" className="add_package_btn">
    //       Add Banner
    //     </button>
    //   </form>
    // </div>
  );
};

export default AddBanner;
