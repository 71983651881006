import React, { useState } from "react";
import {
  Box,
  Button,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import CustomLink from "../../../components/CustomLink";
import FormHeading from "../../../components/FormHeading";
import "../../Manage/ManagePackage/AddPackage.css";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { uploadReports } from "../../../actions/orderActions";
import { API } from "../../../api";
import { PictureAsPdf } from "@mui/icons-material";

const UploadReports = ({ handleClose, selectedOrder }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  console.log(selectedOrder);

  const matches = useMediaQuery("(min-width:600px)");

  const message = `Great news, ${selectedOrder.userName}! Your diagnostic report is now available on our website. We've analyzed your results and put together a comprehensive report. Please log in to our website to view your report, or find it attached to this message. Let us know if you have any questions. Thanks for choosing our service!`;

  const [rep, setRep] = useState({
    report: "",
    eReport: "",
  });
  const handleUploadRoutes = async (e) => {
    e.preventDefault();
    if (rep.report !== "") {
      dispatch(uploadReports({ ...selectedOrder, ...rep }));
      navigate("/dashboard/orders");
      handleClose();

      await axios.get(
        `https://apibuddy.in/api/send.php?number=91${selectedOrder.userNo}&type=media&message=${message}&media_url=https://server.labtin.com/${rep.report}&instance_id=642D200B37452&access_token=b844caf2166f3530402c6dbfed12f81d`
      );
    }
  };
  const uploadFileHandler = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();

    formData.append("file", file);

    const { data } = await API.post("/upload", formData);
    console.log(data);
    setRep({
      ...rep,
      report: data[0],
    });
  };
  const uploadEFileHandler = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();

    formData.append("file", file);

    const { data } = await API.post("/upload", formData);
    console.log(data);
    setRep({
      ...rep,
      eReport: data[0],
    });
  };
  return (
    <Stack p={4} spacing={matches ? 3 : 1}>
      <Typography
        variant="h4"
        sx={{ textAlign: "center", fontSize: "1.4rem", fontWeight: "700" }}
      >
        Upload Reports
      </Typography>
      <Divider />
      <Stack>
        <FormControl>
          <Stack spacing={4}>
            <Box display="flex" flexDirection="column">
              <Box display="flex" alignItems="center">
                <Typography sx={{ fontSize: "1.2rem", fontWeight: 600 }}>
                  Upload Report
                </Typography>
                <Button fullWidth={false} component="label" sx={{ m: 5 }}>
                  Upload
                  <input
                    onChange={uploadFileHandler}
                    style={{ display: "none" }}
                    type="file"
                    name="bannerImage"
                    id="bannerImage"
                  />
                </Button>
              </Box>
              {rep.report && (
                <Box display="flex" alignItems="center">
                  <PictureAsPdf /> <Typography>{rep.report}</Typography>
                </Box>
              )}
            </Box>
            <Divider />
            <Box display="flex" flexDirection="column">
              <Box display="flex" alignItems="center">
                <Typography sx={{ fontSize: "1.2rem", fontWeight: 600 }}>
                  Upload EReport
                </Typography>
                <Button fullWidth={false} component="label" sx={{ m: 5 }}>
                  Upload
                  <input
                    onChange={uploadEFileHandler}
                    style={{ display: "none" }}
                    type="file"
                    name="bannerImage"
                    id="bannerImage"
                  />
                </Button>
              </Box>
              {rep.eReport && (
                <Box display="flex" alignItems="center">
                  <PictureAsPdf /> <Typography>{rep.eReport}</Typography>
                </Box>
              )}
            </Box>

            <Button variant="contained" onClick={handleUploadRoutes}>
              Submit
            </Button>
          </Stack>
        </FormControl>
      </Stack>
    </Stack>
    // <div className="add_package">
    //   <FormHeading>Upload Reports</FormHeading>
    //   <form onSubmit={handleUploadRoutes}>
    //     <input
    //       type="file"
    //       name="Report"
    //       placeholder="Report"
    //       onChange={uploadFileHandler}
    //     />
    //     <input
    //       type="file"
    //       name="E-Report"
    //       placeholder="E-Report"
    //       onChange={uploadEFileHandler}
    //     />

    //     <button type="submit" className="add_package_btn">
    //       Upload Reports
    //     </button>
    //   </form>
    // </div>
  );
};

export default UploadReports;
