import { Close, PhotoCamera } from "@mui/icons-material";
import React, { useState, useRef } from "react";
import "./PrescriptionDialog.css";
import gallery from "../image/gallery.png";
import { useDispatch } from "react-redux";
import { addPrescription } from "../actions/prescriptionActions";

import axios from "axios";
import { API } from "../api";
import { Box, Button, CircularProgress } from "@mui/material";

function PrescriptionDialog(props) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false)

  const [prescription, setPrescription] = useState({
    name: "",
    mobile: "",
    files: [],
  });
  const [active, setActive] = useState(false);
  const hiddenFileInput = useRef(null);

  const uploadFileHandler = async (e) => {
    setLoading(true)
    const files = e.target.files;
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("file", files[i]);
    }

    const { data } = await API.post("/upload", formData);
    
    console.log(data);
    setPrescription({
      ...prescription,
      files: data,
    });
    setLoading(false);
  };

  const submitHandler = (e) => {
    if(prescription.name === "" || prescription.mobile ===""){
      return;
    }
    e.preventDefault();
    dispatch(addPrescription(prescription));
    setActive(true);

    setPrescription({ name: "", mobile: "", files: [] });
  };
  const okHandler = (e) => {
    e.preventDefault();
    props.onClose();
    setActive(false);
  };
  if (!props.visibility) {
    return null;
  }
  if (active) {
    return (
      <div className="my_modal">
        <div className="thanku">
          <div className="thanku_inner">
            <div>Thank You</div>
            <div className="thanku_info">
              <span>Your prescription has been uploaded.</span>
              <span>Our health advisor will get back to you within 4hrs</span>
            </div>
            <button
              onClick={okHandler}
              style={{
                width: "100px",
                height: "35px",
                fontSize: "15px",
                border: "1px solid var(--color-light)",
                backgroundColor: "deepskyblue",
                color: "white",
                marginTop: "20px",
                borderRadius: "5px",
                marginBottom: "10px",
              }}
            >
              Ok
            </button>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="my_modal">
      <div className="dialog_body">
        <div>
          <div className="heading_and_close">
            <div style={{ flex: "1", paddingLeft: "15px", fontSize: "larger" }}>
              Upload your Prescription
            </div>
            <div
              className="close_button"
              onClick={async () => {
                props.onClose();
              }}
            >
              <Close />
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            {/* <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "baseline",
              }}
            >
              <img src={gallery} onClick={handleClick} height={70}></img>
              <input
                type="file"
                multiple
                ref={hiddenFileInput}
                style={{ display: "none" }}
                onChange={uploadFileHandler}
              />
              <div
                style={{
                  fontWeight: "bold",
                  fontSize: "large",
                }}
              >
                Upload Files
              </div>
            </div> */}
            <Button sx={{ my: 1 }} component="label">
              Upload <PhotoCamera sx={{ ml: "5px" }} />
              <input
                type="file"
                multiple
                ref={hiddenFileInput}
                style={{ display: "none" }}
                onChange={uploadFileHandler}
              />
            </Button>
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              paddingTop: "10px",
            }}
          >
            <div
              style={{
                width: "350px",
                height: "125px",
                backgroundColor: "whitesmoke",
                alignSelf: "center",
              }}
            >
              <div
                style={{
                  textAlign: "center",
                  fontSize: "small",
                  fontWeight: "bold",
                  paddingTop: "10px",
                }}
              >
                Attached Prescriptions
              </div>

              <div className="prec">
                
                {loading ? (
<Box width="100%" height= "4rem" display="flex"  justifyContent="center" alignItems="center">
  <CircularProgress />
</Box>
                ) 
              :
              (
                prescription.files &&
                  prescription.files.map((f) => (
                    <img className="prec_img" src={`https://server.labtin.com/${f}`}></img>
                  )))}
              </div>
            </div>
          </div>
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                textAlign: "center",
                alignItems: "center",
              }}
            >
              <p>Enter Your Details</p>
              <input
                type="text"
                placeholder="Enter Your Name"
                onChange={(e) =>
                  setPrescription({ ...prescription, name: e.target.value })
                }
                style={{
                  width: "200px",
                  height: "15px",
                  fontSize: "15px",
                  padding: "10px",
                  color: "black",
                  borderColor: "black",
                  backgroundColor: "whitesmoke",
                  border: "0.5px solid",
                  marginBottom: "10px",
                  borderRadius: "5px",
                }}
              />
              <input
                type="text"
                placeholder="Mobile Number*"
                onChange={(e) =>
                  setPrescription({ ...prescription, mobile: e.target.value })
                }
                style={{
                  width: "200px",
                  height: "15px",
                  fontSize: "15px",
                  padding: "10px",
                  color: "black",
                  borderColor: "black",
                  backgroundColor: "whitesmoke",
                  border: "0.5px solid",
                  borderRadius: "5px",
                }}
              />
              <button
                onClick={submitHandler}
                style={{
                  width: "100px",
                  height: "35px",
                  fontSize: "15px",
                  border: "1px solid var(--color-light)",
                  backgroundColor: "deepskyblue",
                  color: "white",
                  marginTop: "20px",
                  borderRadius: "5px",
                  marginBottom: "10px",
                }}
              >
                SUBMIT
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrescriptionDialog;
