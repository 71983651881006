import "./imageCarousel.css";
import { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useDispatch, useSelector } from "react-redux";
import { getBanners } from "../../actions/appearanceActions";
import { ArrowForwardIos, ArrowBackIos } from "@mui/icons-material";
import { IconButton } from "@mui/material";

export default function ImageCarousel() {
  const dispatch = useDispatch();
  const [imageIndex, setImageIndex] = useState(0);

  const { bannerList } = useSelector((state) => state.banners);

  useEffect(() => {
    dispatch(getBanners());
  }, [dispatch]);

  const NextArrow = ({ onClick }) => {
    return (
      <div className="arrow nextArrow" onClick={onClick}>
        <ArrowForwardIos />
      </div>
    );
  };

  const PrevArrow = ({ onClick }) => {
    return (
      <div className="arrow prevArrow" onClick={onClick}>
        <ArrowBackIos sx={{ ml: "5px" }} color="#424242" />
      </div>
    );
  };

  const settings = {
    dots: true,
    infinity: true,
    lazyLoad: true,
    autoplay: true,
    autoplaySpeed: 3500,
    // speed: 300,

    slidesToShow: 1,
    centerMode: true,
    centerPadding: "0px",
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,

    beforeChange: (current, next) => setImageIndex(next),
  };

  return (
    <div className="imageCarousel">
      <Slider dotsClass="slick-dots dots-style" {...settings}>
        {bannerList.map((banner, index) => (
          <div
            className={index === imageIndex ? "slide activeSlide" : "slide"}
            key={index}
            onClick={() =>
              banner.link != "#" && window.open(banner.link, "_blank")
            }
          >
            <img
              src={`https://server.labtin.com/${banner.img}`}
              // src="/random.jpg"
              alt="banner"
              onError={(event) => {
                event.target.src = "/noImage.png";
                event.onerror = null;
              }}
            />
          </div>
        ))}
      </Slider>
    </div>
  );
}
