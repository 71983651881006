import { useEffect, useState } from "react";
import "../../App.css";
import "../../Dashboard/Manage/ManagePackage/ManagePackage.css";
import { useDispatch, useSelector } from "react-redux";
import "./MyInfo.css";
import { getMyOrders } from "../../actions/orderActions";
import {
  Button,
  Typography,
  Box,
  Stack,
  Avatar,
  IconButton,
  Chip,
  Divider,
  CircularProgress,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import { stringToColor } from "../Avatar";
import { Done, NavigateBefore } from "@mui/icons-material";
import { FaFilePdf } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { userDetails } from "../../actions/userActions";

const MyReports = () => {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const { myOrders } = useSelector((state) => state.orders);

  const handleSelect = (ord) => {
    console.log({ name: ord.name, age: ord.age, sex: ord.sex }, selected);
    const check = selected.find(
      (s) => s.name === ord.name && s.age === ord.age && s.sex === ord.sex
    );
    console.log(check);
    if (check) {
      setSelected(
        selected.filter(
          (s) =>
            !(s.sex === ord.sex && s.name === ord.name && s.age === ord.age)
        )
      );
    } else {
      setSelected([
        ...selected,
        { name: ord.name, age: ord.age, sex: ord.sex },
      ]);
    }
  };

  const { user } = useSelector((state) => state.userDetails);
  console.log(user);
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (!userInfo) {
      navigate("/login");
    }
    if (!user._id) {
      dispatch(userDetails());
    }
  }, [dispatch, navigate]);

  useEffect(() => {
    dispatch(getMyOrders());
  }, [dispatch]);
  console.log(loading);
  return loading || !userInfo || !user.users ? (
    <Box
      height="80vh"
      width="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <CircularProgress />
    </Box>
  ) : (
    <Box
      pt={{ xs: "0.5rem", sm: "1rem" }}
      pb={{ xs: "5rem", sm: "1rem" }}
      px={{ xs: "1rem", sm: "4rem" }}
      bgcolor="#eeeeee"
      minHeight="80vh"
    >
      <Box
        width="95%"
        height={{ xs: "3rem", sm: "3.5rem" }}
        bgcolor="white"
        display="flex"
        alignItems="center"
        px="0.5rem"
      >
        <IconButton onClick={() => navigate("/")}>
          <NavigateBefore sx={{ color: "#0d47a1" }} />
        </IconButton>
        <Typography
          color="#0d47a1"
          fontSize={{ xs: "1.2rem", sm: "1.5rem" }}
          fontWeight="700"
          ml={3}
        >
          My Reports
        </Typography>
      </Box>
      <Box
        display="flex"
        flexWrap={{ xs: "nowrap", sm: "wrap" }}
        sx={{ overflowX: "auto", width: "95%" }}
        // pr="0.5rem"
      >
        {user.users.map((ord, index) => (
          <Stack
            key={index}
            alignItems="center"
            my={1.5}
            mx={
              selected.find(
                (s) =>
                  s.name === ord.name && s.age === ord.age && s.sex === ord.sex
              )
                ? 0.5
                : 0.5
            }
            borderRadius="10px"
            border={
              selected.find(
                (s) =>
                  s.name === ord.name && s.age === ord.age && s.sex === ord.sex
              )
                ? "2px solid #424242"
                : "2px solid #eeeeee"
            }
            py={{ xs: 0.7, sm: 1 }}
            px={{ xs: 0, sm: 1 }}
            justifyContent="center"
            position="relative"
            sx={{ cursor: "pointer" }}
            minWidth={
              selected.find(
                (s) =>
                  s.name === ord.name && s.age === ord.age && s.sex === ord.sex
              )
                ? { xs: "5rem", sm: "5rem", md: "5rem" }
                : { xs: "5rem", sm: "5rem", md: "5rem" }
            }
            onClick={() => handleSelect(ord)}
          >
            {selected.find(
              (s) =>
                s.name === ord.name && s.age === ord.age && s.sex === ord.sex
            ) && (
              <Box
                border="2px solid #0d47a1"
                borderRadius="50%"
                display="flex"
                alignItems="center"
                justifyContent="center"
                position="absolute"
                top="2px"
                right="2px"
              >
                <Done
                  sx={{
                    color: "#0d47a1",
                    fontSize: "1rem",
                    fontWeight: "700",
                  }}
                />
              </Box>
            )}
            <Avatar
              sx={{
                width: "50px",
                height: "50px",
                bgcolor: stringToColor(ord.name),
              }}
              children={`${ord?.name.split(" ")[0][0]}`}
              src={ord?.uploadedFiles?.report}
              // {...stringAvatar(ord, "50px", "50px")}
            />
            <Typography
              color="#0d47a1"
              fontSize={{ xs: "0.6rem", sm: "1rem" }}
              fontWeight="600"
              textAlign="center"
              height="27px"
            >
              {ord.name}
            </Typography>
          </Stack>
        ))}
      </Box>

      <Stack direction="row" spacing={1} sx={{ overflowX: "auto" }} pb={1}>
        {selected.map((s) => (
          <Chip label={s.name} onDelete={() => handleSelect(s)} />
        ))}
        {/* <Chip label="Deletable" variant="outlined" onDelete={handleDelete} /> */}
      </Stack>
      {myOrders
        .filter((o) => o.uploadedFiles)
        ?.filter(
          (a) => selected.includes(a.orderedFor) || selected.length === 0
        ).length > 0 ? (
        <Typography fontSize="0.9rem" fontWeight="700" mt={1} color="#616161">
          Showing Reports:{" "}
          {
            myOrders
              .filter((o) => o.uploadedFiles)
              ?.filter(
                (a) => selected.includes(a.orderedFor) || selected.length === 0
              ).length
          }
        </Typography>
      ) : (
        <Typography fontSize="0.9rem" fontWeight="700" mt={1} color="#616161">
          No Reports Available
        </Typography>
      )}
      <Box
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        flexWrap="wrap"
      >
        {myOrders
          .filter((o) => o.uploadedFiles)
          .filter(
            (a) =>
              selected.find(
                (s) =>
                  a.orderedFor.name === s.name &&
                  a.orderedFor.age === s.age &&
                  a.orderedFor.sex === s.sex
              ) || selected.length === 0
          )
          .map((ord, index) => (
            <Box
              width="20rem"
              mx={{ xs: 1, sm: 1, md: 2 }}
              height="8.4rem"
              p={1}
              bgcolor="#f5f5f5"
              my={1}
            >
              <Box
                width="100%"
                height="2rem"
                display="flex"
                alignItems="center"
                color="#424242"
              >
                <Typography fontSize="1rem" fontWeight="700" ml={1}>
                  Ordered on : {new Date(ord.createdAt).toString().slice(0, 15)}
                </Typography>
              </Box>
              <Divider />
              <Box
                display="flex"
                alignItems="center"
                height="5rem"
                py="0.7rem"
                px={0}
              >
                <Box width="4rem" height="100%">
                  <FaFilePdf fontSize={90} />
                </Box>
                <Stack spacing={2} ml={6}>
                  <Typography
                    color="#0d47a1"
                    fontSize="1.1rem"
                    fontWeight="700"
                  >
                    {ord.orderedItem.item?.length > 30
                      ? ord.orderedItem.item.slice(0, 30) + "..."
                      : ord.orderedItem.item}
                  </Typography>
                  <Button
                    size="small"
                    variant="outlined"
                    sx={{ textTransform: "none", pl: 0 }}
                    pl={0}
                    onClick={() => {
                      setLoading(true);
                      // fetch(
                      //   `https://server.labtin.com/${ord.uploadedFiles.report}`
                      // )
                      //   .then((response) => response.blob())
                      //   .then((blob) => {
                      //     const url = window.URL.createObjectURL(
                      //       new Blob([blob])
                      //     );
                      //     const link = document.createElement("a");
                      //     link.href = url;
                      //     link.setAttribute("download", `${ord.orderedFor.name}-labtin.pdf`);
                      //     document.body.appendChild(link);
                      //     link.click();
                      //     link.parentNode.removeChild(link);
                      //     setLoading(false)
                      //   });
                      const url =
                        "https://server.labtin.com/" + ord.uploadedFiles.report;
                      const link = document.createElement("a");
                      link.href = url;
                      link.target = "";
                      document.body.appendChild(link);
                      link.click();
                      document.body.removeChild(link);

                      // setLoading(false);
                    }}
                  >
                    <DownloadIcon />
                    Dowload Report
                  </Button>
                </Stack>
              </Box>
            </Box>
          ))}
      </Box>
    </Box>
    // <div className="myBookings">
    //   {myOrders
    //     .filter((o) => o.uploadedFiles)
    //     .map((ord, index) => (
    //       <Box
    //         width={{ xs: "20rem", sm: "21.5rem", md: "23rem" }}
    //         height="10rem"
    //         bgcolor="#f5f5f5"
    //       >
    //         <Box
    //           width="100%"
    //           height="3rem"
    //           bgcolor="#0eb6ff"
    //           color="white"
    //           display="flex"
    //           alignItems="center"
    //         >
    //           <Typography fontSize="1.2rem" fontWeight="700" ml={3}>
    //             Order 00{index + 1}
    //           </Typography>
    //         </Box>
    //         <Box
    //           display="flex"
    //           alignItems="center"
    //           justifyContent="space-between"
    //           height="7rem"
    //           px="1rem"
    //           my="auto"
    //         >
    //           <div className="left">
    //             <img
    //               alt="Logo"
    //               src={ord.uploadedFiles.report}
    //               style={{
    //                 width: "4rem",
    //                 height: "4rem",
    //                 border: "1px solid grey",
    //                 bgcolor: "purple",
    //                 borderRadius: "50%",
    //               }}
    //             />
    //           </div>
    //           <Stack spacing={2} height="7rem" justifyContent="center">
    //             <Typography fontSize="1rem" fontWeight="700">
    //               {ord.orderedItem.item}
    //             </Typography>
    //             <Button
    //               size="small"
    //               variant="contained"
    //               onClick={() => {
    //                 fetch(ord.uploadedFiles.report).then((response) => {
    //                   response.blob().then((blob) => {
    //                     const fileURL = window.URL.createObjectURL(blob);
    //                     let alink = document.createElement("a");
    //                     alink.href = fileURL;
    //                     alink.download = "Report.pdf";
    //                     alink.click();
    //                   });
    //                 });
    //               }}
    //             >
    //               Download Report
    //             </Button>
    //           </Stack>
    //         </Box>
    //       </Box>

    //     ))}
    // </div>
  );
};

export default MyReports;
