import { Box, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function HealthIssues({ index, data }) {
  const rows = [
    { t: "#95A6FEff", b: "#A8B9FDff" },
    { t: "#7ccfbd", b: "#ace2d5" },
    { t: "#e87d6b", b: "#f1a597" },
  ];
  const { t, b } = rows[index % 3];
  const navigate = useNavigate();

  console.log(data);

  return (
    <Box
      sx={{
        cursor: "pointer",
        background: `linear-gradient(173deg, ${t} 50%, ${b} 50%)`,
        // transform: `skew(-10deg)`,
        transformOrigin: `top left`,
        height: { xs: "170px", sm: "200px" },
        width: { xs: "160px", sm: "180px" },
        mx: { xs: "2px", sm: "10px" },
        borderRadius: "10px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        padding: "10px",
        pb: 0,
        mb: 2,
      }}
      onClick={() => {
        navigate(`/healthissue/${data._id}`);
        // window.open(`/healthissue/${data._id}`, "_blank");
      }}
    >
      <Stack p={1}>
        <Typography
          sx={{ fontSize: "1.1rem", fontWeight: "600", color: "white" }}
        >
          {data.title}
        </Typography>
        {/* <Typography
          sx={{ fontSize: "0.8rem", fontWeight: "600", color: "#fafafa" }}
        >
          Available Tests: {data.tests.length}
        </Typography>
        <Typography
          sx={{ fontSize: "0.8rem", fontWeight: "600", color: "#fafafa" }}
        >
          Available Packages: {data.packages.length}
        </Typography> */}
      </Stack>
      <img
        src={`https://server.labtin.com/${data.image}`}
        alt="cough"
        style={{
          height: "118px",
          alignSelf: "flex-end",
        }}
      />
    </Box>
  );
}
